/**
 * 添加 beta 标识
 * @returns string
 */
export function beta(config = {}) {
  // 在页面左上角，添加 一个斜的 beta 标识，并且可以配置文字内容、文字颜色，背景颜色
  const {
    text = 'Beta',
    type = 'beta',
    position = 'left-top', // left-top, right-top, left-bottom, right-bottom
    offsetX = 0,
    offsetY = 0,
    textColor = '',
    bgColor = '',
    style = {},
  } = config;
  // 需要通过类型设置不同的颜色，比如 alpha 是红色，beta 是黄色，rc 是蓝色， release 是绿色， dev 是淡蓝色
  // 通过 type 来设置颜色
  const colorMap = {
    alpha: '#f00',
    beta: '#f60',
    rc: '#00f',
    release: '#0f0',
    dev: '#3498db',
  };
  const _bgColor = bgColor || colorMap[type] || 'yellow';
  const _textColor = textColor || 'white';
  const box = document.createElement('div');
  box.style.cssText = `
    width: 200px;
    height: 200px;
    background-color: rgba(0,0,0,0);
    // background-color: #f22;
    position: fixed;
    z-index: 9999;
    pointer-events:none;
    // top: 0;
    // left: 0;
  `;
  const div = document.createElement('div');
  const _offsetX = offsetX || 0;
  const _offsetY = offsetY || -80;
  div.style.cssText = `
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 999;
    transform: rotate(-45deg)  translate(${_offsetX}px, ${_offsetY}px);
    height: 32px;
    line-height: 32px;
    font-weight: bold;
    margin-top: calc(50% - 16px);
    width: 200px;
    background-color: ${_bgColor};
    color: ${_textColor};
    font-size: 14px;
    text-align: center;

  `;
  // 通过 position 来设置位置
  switch (position) {
    case 'lt':
    case 'left-top':
      box.style.left = '0';
      box.style.top = '0';
      div.style.transform = `rotate(-45deg)  translate(${_offsetX}px, ${_offsetY}px)`;
      break;
    case 'rt':
    case 'right-top':
      box.style.right = '0';
      box.style.top = '0';
      div.style.transform = `rotate(45deg)  translate(${-_offsetX}px, ${_offsetY}px)`;
      break;
    case 'lb':
    case 'left-bottom':
      box.style.left = '0';
      box.style.bottom = '0';
      div.style.transform = `rotate(45deg)  translate(${_offsetX}px, ${-_offsetY}px)`;
      break;
    case 'rb':
    case 'right-bottom':
      box.style.right = '0';
      box.style.bottom = '0';
      div.style.transform = `rotate(-45deg)  translate(${-_offsetX}px, ${-_offsetY}px)`;
      break;
    default:
      box.style.left = '0';
      box.style.top = '0';
      div.style.transform = `rotate(-45deg)  translate(${_offsetX}px, ${_offsetY}px)`;
  }
  if (style) {
    div.style.cssText += Object.keys(style)
      .map((key) => {
        // 驼峰转横杠
        const _key = key.replace(/([A-Z])/g, '-$1').toLowerCase();
        return `${_key}: ${style[key]};`;
      })
      .join('');
  }
  div.innerText = text;
  box.appendChild(div);
  document.body.appendChild(box);
}

export default {
  beta,
};
