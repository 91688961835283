// 运行时配置
import { ProSettings } from '@ant-design/pro-components';
import * as Sentry from '@sentry/react';
import { RunTimeLayoutConfig } from '@umijs/max';
import moment from 'moment';
import 'moment-timezone';
import defaultSettings from '../config/defaultSettings';
import { beta } from './utils/beta';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

// 默认设置北京时间
moment.tz.setDefault('Asia/Shanghai');
// 默认设置北京时间
dayjs.tz.setDefault('Asia/Shanghai');

if (process.env.NODE_ENV !== 'development') {
  // 判断url是否包含test,包含则为测试环境
  if (window.location.href.indexOf('test') === -1 && window.location.href.indexOf('uat-') === -1) {
    // sentry监控
    Sentry.init({
      dsn: 'https://bdd2f53ab96f6ffd720e934f1fe4e229@o324698.ingest.sentry.io/4506182446153728',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', '127.0.0.1', /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } else {
    if (window.location.href.indexOf('test') >= 0) {
      // 添加测试标识
      beta({
        text: 'Test',
        type: 'beta',
        position: 'lt',
      });
    }
    if (window.location.href.indexOf('uat-') >= 0) {
      // 添加测试标识
      beta({
        text: 'UAT',
        type: 'release',
        position: 'lt',
      });
    }
  }
} else {
  // 添加开发标识
  beta({
    text: 'Dev',
    type: 'dev',
    position: 'lt',
  });
}

// /** 获取用户信息比较慢的时候会展示一个 loading */
// export const initialStateConfig = {
//   loading: <PageLoading />,
//   showtool: false,
// };
// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
export async function getInitialState(): Promise<{
  name: string;
  settings?: Partial<ProSettings>;
  loading?: boolean;
  showtool?: boolean;
  userInfo?: any;
  compact?: boolean;
  fetchUserInfo?: () => Promise<any | undefined>;
}> {
  // const fetchUserInfo = async () => {
  //   try {
  //     const msg = await queryCurrentUser();
  //     // console.log('xx', msg);
  //     return msg.data;
  //   } catch (error) {
  //     history.push(loginPath);
  //   }
  //   return undefined;
  // };
  // // 如果不是登录页面，执行
  // if (history.location.pathname !== loginPath) {
  //   const currentUser = await fetchUserInfo();
  //   // history.push(loginPath);
  //   return {
  //     fetchUserInfo,
  //     currentUser,
  //     settings: defaultSettings,
  //   };
  // }
  // 紧凑模式
  let compact = window.localStorage.getItem('compact') || 'true';
  return {
    name: '@umijs/max',
    settings: { ...defaultSettings },
    showtool: false,
    loading: false,
    compact: compact === 'true' ? true : false,
    userInfo: {},
  };
}

// @ts-ignore
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    // logo: 'https://img.alicdn.com/tfs/TB1YHEpwUT1gK0jSZFhXXaAtVXa-28-27.svg',
    logo: false,
    locale: false, // 默认开启，如无需菜单国际化可关闭
    menu: {
      locale: false,
    },
    logout: () => {}, // do something
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};
//  官网路径替换 重写
// export function onRouteChange({
//   location,
//   clientRoutes,
//   routes,
//   action,
//   basename,
//   isFirst,
// }: any) {
//   // console.log(location, clientRoutes, routes, action, basename, isFirst);
//   let { pathname, search } = location;
//   console.log(pathname);

//   if (pathname == '/manage/inspection/inspection_search') {
//     history.push(
//       '/web/quality_control/Sub_homepage/Search/Inspection_Reports' + search,
//     );
//   }
//   if (pathname == '/manage/note/note_search') {
//     history.push(
//       '/web/quality_control/Sub_homepage/Search/Production_Notes' + search,
//     );
//   }
//   if (pathname == '/manage/schedule/pending_schedule_list') {
//     history.push(
//       '/web/quality_control/Sub_homepage/Search/Inspection_Bookings' + search,
//     );
//   }
//   if (pathname == '/manage/tech_note/tech_note_list') {
//     history.push(
//       '/web/quality_control/Sub_homepage/Search/TD_Reports' + search,
//     );
//   }
//   if (pathname == '/manage/fabric_note/fabric_note_list') {
//     history.push(
//       '/web/quality_control/Sub_homepage/Search/Fabric_Reports' + search,
//     );
//   }
//   if (pathname == '/fqa/fqa_list') {
//     history.push('/web/compliance/Search/Factory_Quality_Assessments' + search);
//   }
//   if (pathname == '/fmqa/fmqa_list') {
//     history.push('/web/compliance/Search/Mill_Quality_Assessments' + search);
//   }
//   if (pathname == '/search/compliance_list') {
//     history.push('/web/compliance/Search/Audits' + search);
//   }
//   if (pathname == '/manage/qc_report/inspection_report') {
//     history.push(
//       '/web/quality_control/Sub_homepage/Search/Inspection_Reports/Detail' +
//         search,
//     );
//   }
//   if (pathname == '/search/factory_evaluation_report') {
//     history.push(
//       '/web/compliance/Approvals/CAPs/CAPs_detali/View_Factory_Evaluation' +
//         search,
//     );
//   }
//   if (pathname == '/factory/get_cap_info') {
//     history.push('/web/compliance/Approvals/CAPs/CAPs_detali' + search);
//   }
// }
