// 全局共享数据示例

const useUser = () => {
  // const [uname, setUname] = useState<any>(localStorage.getItem('web_uname'));
  const uname = () => {
    return localStorage.getItem('web_uname') || '';
  };
  const Getuid = () => localStorage.getItem('web_UID') || '';
  const rule = () => {
    try {
      let str = localStorage.getItem('web_rule') || '';
      if (str) {
        return JSON.parse(str);
      }
      return [];
    } catch (error) {
      console.log('rule err:', error);
      return [];
    }
  };
  const _access_id = () => {
    try {
      let str = localStorage.getItem('web_access_id') || '';
      if (str) {
        return JSON.parse(str);
      }
      return [];
    } catch (error) {
      console.log('rule err:', error);
      return [];
    }
  };
  //
  const accept_img =
    '.titf,.jfif,.bmp,.gif,.svg,.jepg,.svgz,.jpg,.webp,.ico,.xbm,.dib,.pjp,.apng,.tif,.pjpeg,.avif';
  return {
    uname,
    rule,
    Getuid,
    _access_id,
    accept_img,
  };
};

export default useUser;
